import React from "react";
import "./OrderCard.css";
import Tabs from "../Tabs/Tabs";
import OrderDetails from "../OrderDescription/OrderDetails";
import OrderButton from "../buttons/OrderButton";
import PhotoGallery from "../PhotoGallery/PhotoGallery";

const OrderCard = () => {
  return (
    <>
      <div className="container">
        {/* Left column */}
        <div className="left-column">
          <PhotoGallery/>
        </div>
        {/* Right column */}
        <div className="right-column">
          <div className="OrderTitle">
            <OrderDetails />
          </div>
          <div>
            <Tabs />
          </div>
          <div className="OrderButton">
            <OrderButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCard;
