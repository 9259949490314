import React from "react";
import "../OrderCard/OrderCard.css";

const PhotoGallery = () => {
  // Define an array of image objects with source and alt text
  const images = [
    { src: "./images/slide1.jpg", alt: "Pic" },
    { src: "./images/slide2.jpg", alt: "Pic" },
    { src: "./images/slide3.jpg", alt: "Pic" },
    { src: "./images/slide4.jpg", alt: "Pic" },
  ];

  // Map over the array to render <img> elements
  return (
    <div className="gallery">
      {images.map((image, index) => (
        <img key={index} src={image.src} alt={image.alt} />
      ))}
    </div>
  );
};

export default PhotoGallery;
