import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container__Footer">
      {/* Address Details */}
      <div className="row__Footer">
        <div className="details--row__Footer">
          <div className="item--details">
            <i class="fa-solid fa-square-phone"></i>
            <p>+260 977752355</p>
          </div>
          <div className="item--details">
            <i class="fa-solid fa-location-dot"></i>
            <p>adnosom@yahoo.com</p>
          </div>
          <div className="item--details">
            <i class="fa-solid fa-envelope"></i>
            <p>
              Suite 606, 6th Floor, <br /> INDECO House, Buteko Place,
              <br />
              Lusaka, Zambia. <br />
            </p>
          </div>
        </div>
        {/* Footer Menu Items */}
        <div className="links--row__Footer">
          <div className="links--wrapper">
            {/* Company Section 1 */}
            <div className="links-section">
              <h3 className="heading_links-section">Company</h3>
              <ul className="links-section-list ui-font">
                <li>
                  <a href="/" className="links-item">
                    Our story
                  </a>
                </li>
                <li>
                  <a href="/" className="links-item">
                    Clients
                  </a>
                </li>
                <li>
                  <a href="/" className="links-item">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            {/* Company Section 1 */}
            <div className="links-section">
              <h2 className="heading_links-section">Services</h2>
              <ul className="links-section-list ui-font">
                <li>
                  <a href="/" className="links-item">
                    Branding
                  </a>
                </li>
                <li>
                  <a href="/" className="links-item">
                    Printing
                  </a>
                </li>
                <li>
                  <a href="/" className="links-item">
                    Multimedia
                  </a>
                </li>
                <li>
                  <a href="/" className="links-item">
                    Media Consultancy
                  </a>
                </li>
              </ul>
            </div>
            {/* Resources Section 3 */}
            <div className="links-section">
              <h2 className="heading_links-section">Resources</h2>
              <ul className="links-section-list ui-font">
                <li>
                  <a href="/" className="links-item">
                    Portfolio
                  </a>
                </li>
                <li>
                  <a href="/" className="links-item">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="/" className="links-item">
                    Community
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Subscribe Form */}
          <div className="subscribe-cta">
            <h2 className="subscribe-heading">Subscribe</h2>
            <form action="#" className="subscribe-form">
              <div className="subscribe-form">
                <input
                  type="text"
                  className="form-input email-input"
                  placeholder="Enter email address"
                />
                <button type="submit" className="form-input submit">
                  Subscribe
                </button>
              </div>
              <span className="cta-subheading">
                Get digital marketing updates in your mailbox
              </span>
            </form>
          </div>
        </div>
        {/* Footer Base */}
        <div className="base--row__Footer">
          <div className="container_base--row__Footer">
            <div className="copyright-details">
              <p className="copyright-text">
                Copyright © All rights reserved.{" "}
                <a
                  href="https://bwanji.digital"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Adnosom Enterprises
                </a>
              </p>
            </div>
            <div className="socials">
              <div className="icons--socials">
                <Link
                  target="_blank"
                  to="https://www.facebook.com/AdnosomEnterprises"
                >
                  <i class="fa-brands fa-square-facebook"></i>
                  {/* <img src="/" alt="" /> */}
                </Link>
                <Link
                  target="_blank"
                  to="https://www.youtube.com/@adnosomTV/featured
"
                >
                  <i class="fa-brands fa-square-youtube"></i>
                  {/* <img src="/" alt="YouTube Icon" /> */}
                </Link>
                <Link target="_blank" to="https://wa.me/260977752355">
                  <i class="fa-brands fa-square-whatsapp"></i>
                  {/* <img src="/" alt="Whatsapp" /> */}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
