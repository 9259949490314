import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonSecondary from "../buttons/ButtonSecondary";
import "./ServiceName.css";

const ServiceName = ({
  PicOne = "/images/branding-big.jpg",
  PicTwo = "/images/branding-medium.jpg",
  BigPic = "/images/branding-big.jpg",
  Heading = "Name Of Service",
  Subheading = "Description of A Service",
  ButtonPrimaryLink = "/orderNow",
  ButtonPrimaryText = "Order Now",
  ButtonSecondaryLink = "/branding",
  ButtonSecondaryText = "Learn More",
}) => {
  return (
    <div className="row__ServiceName">
      <div className="container__ServiceName">
        <picture className="image__ServiceName">
          <source media="(min-width: 1200px)" srcSet={PicOne} />
          <source media="(min-width: 360px)" srcSet={PicTwo} />
          <img src="{BigPic}" alt="Background Pic" />
        </picture>
        <div className="content__ServiceName">
          <div className="title__ServiceName">
            <h2 className="heading__ServiceName">{Heading}</h2>
            <h4 className="subheading__ServiceName">{Subheading}</h4>
          </div>
          <div className="buttons__ServiceName">
            <div className="cta__wrapper">
              {/* <a href={ButtonPrimaryLink} className="button primaryButton">
                {ButtonPrimaryText}
              </a> */}

              {/* <a href={Button2Link} className="button secondaryButton">
                {Button2Text}
              </a> */}

              <ButtonPrimary
                ButtonPrimaryLink={ButtonPrimaryLink}
                ButtonPrimaryText={ButtonPrimaryText}
              />

              <ButtonSecondary
                ButtonSecondaryLink={ButtonSecondaryLink}
                ButtonSecondaryText={ButtonSecondaryText}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceName;
