import "./navbar.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Headroom from "react-headroom";

const Navbar = () => {
  return (
    <div>
      <Headroom>
        <nav>
          <input type="checkbox" id="sidebar-active" />
          <label for="sidebar-active" className="open-sidebar-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="32"
              viewBox="0 -960 960 960"
              width="32"
            >
              <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
            </svg>
          </label>
          <label id="overlay" for="sidebar-active"></label>

          <div className="links-container">
            <label for="sidebar-active" class="close-sidebar-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="32"
                viewBox="0 -960 960 960"
                width="32"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </label>
            <motion.div
              className="logo left"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Link to="./">
                <img src="../../logo.png" alt="Logo" />
              </Link>
            </motion.div>
            <motion.div
              className="menu-items ui-font"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Link to="/">Home</Link>
              <Link to="/#">About</Link>
              <Link to="/#">Portfolio</Link>
              <Link to="/#">Contact Us</Link>
            </motion.div>
            <motion.div
              className="social-media-icons"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Link
                target="_blank"
                to="https://www.facebook.com/AdnosomEnterprises"
              >
                <i class="fa-brands fa-square-facebook"></i>
                {/* <img src="/" alt="" /> */}
              </Link>
              <Link
                target="_blank"
                to="https://www.youtube.com/@adnosomTV/featured
"
              >
                <i class="fa-brands fa-square-youtube"></i>
                {/* <img src="/" alt="YouTube Icon" /> */}
              </Link>
              <Link target="_blank" to="https://wa.me/260977752355">
                <i class="fa-brands fa-square-whatsapp"></i>
                {/* <img src="/" alt="Whatsapp" /> */}
              </Link>
            </motion.div>
          </div>
        </nav>
      </Headroom>
    </div>
  );
};

export default Navbar;
