import React from "react";
import HeroHome from "../components/heroHome/HeroHome";
import ServiceName from "../components/HeroService/ServiceName";

export const Home = () => {
  return (
    <div className="page-container">
      <section>
        <HeroHome />
      </section>
      {/* Branding Section */}
      <section>
        <ServiceName
          PicOne="/images/branding-big.jpg"
          PicTwo="/images/branding-medium.jpg"
          BigPic="/images/branding-big.jpg"
          Heading="Branding That Matters"
          Subheading="Crafting Unique Identities"
          // ButtonPrimaryLink="/brandingorder"
          // ButtonPrimaryText="Order Now"
          // ButtonSecondaryLink="/branding"
          // ButtonSecondaryText="Learn More"
          ButtonPrimaryLink="/#"
          ButtonPrimaryText="Order Now"
          ButtonSecondaryLink="/#"
          ButtonSecondaryText="Learn More"
        />
      </section>
      {/* Printing Section */}
      <section>
        <ServiceName
          PicOne="/images/printing-big.jpg"
          PicTwo="/images/printing-medium.jpg"
          BigPic="/images/printing-big.jpg"
          Heading="Print. Design. Deliver."
          Subheading="Professional Graphics for Every Business Need"
          // ButtonPrimaryLink="/orderNow"
          // ButtonPrimaryText="Order Now"
          // ButtonSecondaryLink="/printing"
          // ButtonSecondaryText="Learn More"
          ButtonPrimaryLink="/#"
          ButtonPrimaryText="Order Now"
          ButtonSecondaryLink="/#"
          ButtonSecondaryText="Learn More"
        />
      </section>
      {/* Signage Section */}
      <section>
        <ServiceName
          PicOne="/images/signage-big.jpg"
          PicTwo="/images/signage-medium.jpg"
          BigPic="/images/signage-big.jpg"
          Heading="Signage That Speaks"
          Subheading="Bold, Clear, Impactful"
          // ButtonPrimaryLink="/orderNow"
          // ButtonPrimaryText="Order Now"
          // ButtonSecondaryLink="/signage"
          // ButtonSecondaryText="Learn More"
          ButtonPrimaryLink="/#"
          ButtonPrimaryText="Order Now"
          ButtonSecondaryLink="/#"
          ButtonSecondaryText="Learn More"
        />
      </section>
      {/* Multimedia Section */}
      <section>
        <ServiceName
          PicOne="/images/multi-big.jpg"
          PicTwo="/images/multi-medium.jpg"
          BigPic="/images/multi-big.jpg"
          Heading="Engage Through Multimedia"
          Subheading="Dynamic Content, Lasting Impressions"
          // ButtonPrimaryLink="/orderNow"
          // ButtonPrimaryText="Order Now"
          // ButtonSecondaryLink="/multimedia"
          // ButtonSecondaryText="Learn More"
          ButtonPrimaryLink="/#"
          ButtonPrimaryText="Order Now"
          ButtonSecondaryLink="/#"
          ButtonSecondaryText="Learn More"
        />
      </section>
      {/* Media Consultancy */}
      <section>
        <ServiceName
          PicOne="/images/media-big.jpg"
          PicTwo="/images/media-medium.jpg"
          BigPic="/images/media-big.jpg"
          Heading="Media Strategy Experts"
          Subheading="Tailored Insights, Proven Results"
          // ButtonPrimaryLink="/orderNow"
          // ButtonPrimaryText="Order Now"
          // ButtonSecondaryLink="/mediaconsultancy"
          // ButtonSecondaryText="Learn More"
          ButtonPrimaryLink="/#"
          ButtonPrimaryText="Order Now"
          ButtonSecondaryLink="/#"
          ButtonSecondaryText="Learn More"
        />
      </section>
    </div>
  );
};
