import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/Home";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Branding from "./pages/Branding";
import OrderCard from "./components/OrderCard/OrderCard";
import NotFoundPage from "./NotFoundPage";
import About from "./pages/About";
import MediaConsultancy from "./pages/MediaConsultancy";
import MultiMedia from "./pages/MultiMedia";
import Printing from "./pages/Printing";
import Signage from "./pages/Signage";
import ContactUs from "./pages/ContactUs";
import Portfolio from "./pages/Portfolio";
// import OrderBranding from "./pages/OrderBranding";
import BuyBranding from "./pages/OrderBranding";

const Layout = () => {
  return (
    <div className="app">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

const Short = () => {
  return (
    <div className="app">
      <Navbar />
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/", // Use a meaningful name for the route
        element: <Home />,
      },
      {
        path: "/about", // Use a meaningful name for the route
        element: <About />,
      },
      {
        path: "/branding", // Use a meaningful name for the route
        element: <Branding />,
      },
      {
        path: "/contact", // Use a meaningful name for the route
        element: <ContactUs />,
      },
      {
        path: "/mediaconsultancy", // Use a meaningful name for the route
        element: <MediaConsultancy />,
      },
      {
        path: "/multimedia", // Use a meaningful name for the route
        element: <MultiMedia />,
      },
      {
        path: "/portfolio", // Use a meaningful name for the route
        element: <Portfolio />,
      },
      {
        path: "/printing", // Use a meaningful name for the route
        element: <Printing />,
      },
      {
        path: "/signage", // Use a meaningful name for the route
        element: <Signage />,
      },
    ],
  },
  {
    path: "/orderNow", // This is the default route
    element: <Short />,
    children: [
      {
        path: "/orderNow", // Use a meaningful name for the route
        element: <OrderCard />,
      },
    ],
  },
  {
    path: "*", // Add this wildcard route to handle all unmatched URLs
    element: <NotFoundPage />, // Render NotFoundPage for all other routes
  },
  {
    path: "/brandingorder", // This is the default route
    element: <Short />,
    children: [
      {
        path: "/brandingorder", // Use a meaningful name for the route
        element: <BuyBranding />,
      },
    ],
  },
]);

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
