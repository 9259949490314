import "./HeroHome.css";

const HeroHome = () => {
  return (
    <div className="row__HeroHome">
      <div className="container__HeroHome">
        <div className="cta__HeroHome">
          <h4 className="topheading__HeroHome">
            Start Your Journey to Media Excellence
          </h4>
          <h2 className="heading__HeroHome">
            Get a Free Media Consultation Today!
          </h2>
          <p className="subheading__HeroHome">
            Unlock personalized strategies to elevate your brand’s media
            presence. Fill out the form below, and let’s begin shaping your
            unique voice and impact in the digital world.
          </p>
          <form action="" className="form__HeroHome">
            <label htmlFor="name" className="fieldLabel__Home">
              Your Name
            </label>
            <input
              id="name"
              type="text"
              placeholder="Enter your name"
              className="field__HeroHome"
            />
            <label htmlFor="email" className="fieldLabel__Home">
              Email address
            </label>
            <input
              id="email"
              type="email"
              placeholder="Enter your email address"
              className="field__HeroHome"
            />
            <input
              type="button"
              value=" Get My Free Consultation"
              className="field__HeroHome cta-btn__HeroHome"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default HeroHome;
