import React from 'react'

const MultiMedia = () => {
  return (
    <div>
      <header>
        <h3>Multimedia Services</h3>
        <h1>
          Captivating Stories, Stunning Visuals, and Impactful Experiences
        </h1>
      </header>
      <section>
        <h2>Our Offerings</h2>
        <div>
          <h4>Video Production</h4>
          <p>
            From concept to completion, we handle every aspect of video
            production. Whether you need promotional videos, explainer videos,
            documentaries, or event coverage, our team brings your story to life
            with creativity and precision.
          </p>
          <ul>
            <li>Corporate Videos & Promos</li>
            <li>Event Coverage</li>
            <li>Explainer Videos</li>
            <li>Documentaries</li>
            <li>Social Media Videos</li>
          </ul>
        </div>
        <div>
          <h4>Motion Graphics & Animation</h4>
          <p>
            Engage your audience with vibrant motion graphics and animations. We
            craft custom 2D and 3D animations that add a unique flair to your
            presentations, ads, and explainer videos.
          </p>
          <ul>
            <li>2D/3D Animation</li>
            <li>Infographic Videos</li>
            <li>Animated Presentations</li>
            <li>Brand Storytelling Animations</li>
          </ul>
        </div>
        <div>
          <h4>Photography Services</h4>
          <p>
            Capture the essence of your brand, products, or events through our
            professional photography services. We focus on delivering
            high-quality images that speak volumes, whether for marketing
            materials, corporate events, or product showcases.
          </p>
          <ul>
            <li>Corporate Photography</li>
            <li>Product Photography</li>
            <li>Event Photography</li>
            <li>Lifestyle Photography</li>
          </ul>
        </div>
        <div>
          <h4>Audio Production</h4>
          <p>
            Sound is a powerful tool in storytelling. Our audio production
            services ensure that your message is delivered with clarity and
            impact. From voiceovers and podcasts to jingles and sound design,
            we’ve got your audio needs covered.
          </p>
          <ul>
            <li>Voiceovers & Narration</li>
            <li>Podcasts & Interviews</li>
            <li>Sound Design & Editing</li>
            <li>Music Production & Jingles</li>
          </ul>
        </div>
      </section>
      <section>
        <h2>Our Process</h2>
        <ul>
          <li>
            <h4>Consultation & Strategy</h4>
            <p>Understanding your needs, audience, and goals.</p>
          </li>
          <li>
            <h4>Concept Development</h4>
            <p>Crafting the storyline, design, and creative vision.</p>
          </li>
          <li>
            <h4>Production & Creation</h4>
            <p>
              Bringing the concept to life through video, photography,
              animation, and more.
            </p>
          </li>
          <li>
            <h4>Editing & Post-Production</h4>
            <p>
              Fine-tuning the content to ensure it aligns with your objectives
              and resonates with your audience.
            </p>
          </li>
          <li>
            <h4>Delivery & Distribution</h4>
            <p>
              {" "}
              Providing you with the final content in your preferred format and
              assisting with deployment.
            </p>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default MultiMedia