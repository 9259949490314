import "./button.css"

const ButtonSecondary = ({
  ButtonSecondaryLink="#",
  ButtonSecondaryText="Button Title"
}) => {
  return (
    <div className="container__Button">
      <a href={ButtonSecondaryLink} className="button secondaryButton">
        {ButtonSecondaryText}
      </a>
    </div>
  );
}

export default ButtonSecondary;