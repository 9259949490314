import React from "react";
import "./NotFoundPage.css";


const NotFoundPage = () => {
  return (
    <div className="container_notFoundPage">
      <div className="content_notFoundPage">
        <div className="text_notFoundPage">
          {/* <h3>404 Error</h3> */}
          <h1>Aww, Navigation Error</h1>
          <p>
            It seems you've taken a wrong turn, but don’t worry, our team is
            working to get you back on track.
          </p>
        </div>
        <div className="buttons_notFoundPage">
          <a className="home_button" href="/">
            Back To Home Page
          </a>
          <a  href="https://wa.me/+260977752355">Call or Text on WhatsApp</a>
        </div>
      </div>
      <div className="image_notFoundPage">
        <img src="./images/404.svg" alt="404 Error Display" />
      </div>
    </div>
  );
};

export default NotFoundPage;
