import React from 'react'

const About = () => {
  return (
    <div>
      <header>
        <h3>About Adnosom Enterprises</h3>
        <h1>Your Trusted Media and Communications Partner</h1>
        <p>
          Adnosom Enterprises is a premier media organization specializing in
          media consultancy, marketing, and advertising.{" "}
        </p>
      </header>
      <section>
        <h2>Our Story</h2>
        <p>
          <strong>Established in 2005</strong>, Adnosom Enterprises has grown
          alongside Zambia's vibrant media landscape. We're a passionate team of
          strategists, creatives, and communication experts dedicated to
          <strong>empowering your brand</strong> and{" "}
          <strong>amplifying your voice</strong>.
        </p>
        <p>
          Our journey began with a simple vision: to bridge the gap between
          effective communication and impactful results. Over the past 15 years,
          we've partnered with a diverse range of clients, from government
          agencies and international organizations to NGOs and private
          companies. Through every project, we've honed our expertise and
          refined our approach, always keeping your goals at the forefront.
        </p>
      </section>
      <section>
        <h2>Why Work With Us?</h2>
        <ul>
          <li>
            <h4>Client-Centric Approach:</h4>
            <p>
              We believe in building strong, collaborative partnerships. We take
              the time to understand your unique needs, challenges, and target
              audiences.
            </p>
          </li>
          <li>
            <h4>Strategic Expertise:</h4>
            <p>
              Our team combines data-driven insights with creative thinking to
              develop communication strategies that resonate.{" "}
            </p>
          </li>
          <li>
            <h4>Results-Oriented:</h4>
            <p>
              We're passionate about measurable outcomes. We track progress and
              demonstrate the value we add to every project.
            </p>
          </li>
          <li>
            <h4>Integrity and Excellence:</h4>
            <p>
              We uphold the highest ethical standards and strive for excellence
              in everything we do.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <h2>What We Do</h2>
        <p>
          We offer a comprehensive suite of services to cater to your specific
          communication needs:
        </p>
        <ul>
          <li>
            <h4>Media and Communications Consultancy:</h4>
            <p>
              We collaborate with you to develop and implement targeted
              communication plans, including social mobilization campaigns,
              public awareness initiatives, and crisis communication strategies.
            </p>
          </li>
          <li>
            <h4>Video Production:</h4>
            <p>
              Our skilled crew creates high-quality video content, from
              documentaries and programs to jingles and advertisements, to
              effectively communicate your message.
            </p>
          </li>
          <li>
            <h4>Marketing and Advertising:</h4>
            <p>
              We assist you with brand management, marketing strategy
              development, and content creation. We leverage print, electronic,
              and social media advertising to ensure impactful brand promotion.
            </p>
          </li>
          <li>
            <h4>IEC Material Production:</h4>
            <p>
              We design and produce creative and informative brochures,
              newsletters, flyers, billboards, and other IEC materials to
              support your communication goals.
            </p>
          </li>
          <li>
            <h4>Mass Media Research:</h4>
            <p>
              We conduct research to identify effective media messages for your
              target audiences, including public opinion surveys and audience
              research for optimal advertising and marketing campaigns.
            </p>
          </li>
          <li>
            <h4>Media Buying and Placement:</h4>
            <p>
              We leverage our media expertise to secure placements for your
              advertisements on prominent platforms such as television, radio,
              newspapers, and online media.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <h2>Our Work Speaks for Itself</h2>
        {/* Think of using the customer logos carousel here */}
        <p>
          Over the past 15 years, we've had the pleasure of collaborating with a
          diverse range of clients, including:
        </p>
        <div>
          <ul>
            <li>
              <h4>Government Agencies</h4>
              <ul>
                <li>Ministry of Tourism</li>
                <li>Ministry of Health</li>
              </ul>
            </li>
            <li>
              <h4>International Organizations</h4>
              <ul>
                <li>USAID</li>
                <li>ILO</li>
                <li>IOM</li>
              </ul>
            </li>
            <li>
              <h4>NGOs</h4>
              <ul>
                <li>CHAMP</li>
                <li>Pact Zambia</li>
                <li>Plan International</li>
              </ul>
            </li>
            <li>
              <h4>Private Companies</h4>
              <ul>
                <li>Safintra Roofing</li>
                <li>NATSAVE Bank</li>
                <li>Celpay</li>
                <li>Toyota Zambia</li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <h2>Why Choose Adnosom Enterprises?</h2>
        <ul>
          <li>
            <h4>Extensive Experience</h4>
            <p>
              We have over 15 years of experience in the media and
              communications industry.
            </p>
          </li>
          <li>
            <h4>Comprehensive Services</h4>
            <p>
              We offer a wide range of services to meet your unique
              communication needs.
            </p>
          </li>
          <li>
            <h4>Skilled Team</h4>
            <p>
              Our team of experts possesses the knowledge and experience to
              deliver exceptional results.
            </p>
          </li>
          <li>
            <h4>Client-Centric Approach</h4>
            <p>
              We prioritize building strong relationships with our clients and
              understanding their specific goals.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <h2>
          Contact Adnosom Enterprises today to discuss how we can help you
          achieve your communication objectives.
        </h2>
        <div>
          <h3>Form</h3>
        </div>
      </section>
    </div>
  );
}

export default About