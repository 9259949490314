import React from "react";

const Printing = () => {
  return (
    <div>
      <header>
        <h3>Printing Services</h3>
        <h1>High-Quality Printing Solutions for All Your Needs</h1>
        <p>
          We bring your ideas to life with our top-notch printing services.
          Whether you're looking for business essentials or creative marketing
          materials, our professional team is ready to deliver vibrant, precise,
          and durable print products that leave a lasting impression.
        </p>
      </header>
      <section>
        <h2>Our Printing Services</h2>
        <div>
          <h4>Business Stationery</h4>
          <p>
            Create a professional and cohesive look with custom-designed and
            printed stationery. We offer:
          </p>
          <ul>
            <li>Business Cards</li>
            <li>Letterheads</li>
            <li>Envelopes</li>
            <li>Presentation Folders</li>
          </ul>
        </div>
        <div>
          <h4>Marketing Collateral</h4>
          <p>
            Boost your brand presence with eye-catching marketing materials. Our
            services cover:
          </p>
          <ul>
            <li>Brochures & Flyers</li>
            <li>Posters</li>
            <li>Banners & Signage</li>
            <li>Catalogs & Booklets</li>
          </ul>
        </div>
        <div>
          <h4>Large Format Printing</h4>
          <p>
            Make a big impact with large format prints. Ideal for events, trade
            shows, and promotional campaigns:
          </p>
          <ul>
            <li>Vinyl Banners</li>
            <li>Roll-up Banners</li>
            <li>Wall & Window Graphics</li>
            <li>Outdoor Signage</li>
          </ul>
        </div>
        <div>
          <h4>Custom Promotional Items</h4>
          <p>Leave a mark with custom-branded items tailored to your needs:</p>
          <ul>
            <li>Stickers & Labels</li>
            <li>Custom Packaging</li>
            <li>Branded Apparel</li>
            <li>Giveaways & Merchandise</li>
          </ul>
        </div>
      </section>
      <section>
        <div>
          <h2>Get a Quote Today!</h2>
          <p>
            Ready to get started? Contact us to discuss your printing needs,
            request a custom quote, or schedule a consultation with our team.
            We’re here to bring your vision to life—one print at a time.
          </p>
        </div>
        <div>
          <h2>Contact Us</h2>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat,
            ipsam voluptate. Esse dolorum saepe non quos enim voluptas, aperiam
            at, alias mollitia facilis autem adipisci tempora porro. Culpa,
            similique natus.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Printing;
