import React from "react";

const OrderButton = ({
  OrderButtonLink = "#",
  OrderButtonText = "Order Now",
}) => {
  return (
    <div>
      <a href={OrderButtonLink} className="OrderButton">
        {OrderButtonText}
      </a>
    </div>
  );
};

export default OrderButton;
