import React from 'react'
// import ServiceHeader from '../components/ServiceHeader/ServiceHeader'
// import ServiceIntro from '../components/ServiceIntro/ServiceIntro';
// import ServiceSlider from '../components/ServiceSlider/ServiceSlider';

const Branding = () => {
  return (
    <div>
      {/* <section>
        <ServiceHeader />
      </section> */}
      {/* <section>
        <ServiceIntro />
      </section> */}
      <header className="header_Branding">
        <div className='header_Branding_text'>
          <h1>Branding Services</h1>
          <h3>Crafting Your Unique Identity</h3>
          <p>
            In today's competitive landscape, a strong brand is more than just a
            logo—it's the essence of who you are, what you stand for, and how
            you connect with your audience. Our branding services are designed
            to help you establish a distinct and memorable identity that
            resonates with your customers and stands out in the market.
          </p>
        </div>
        <div>
          <img src="./images/branding-small.jpg" alt="" />
        </div>
      </header>
      <section>
        <h2>Our Approach</h2>
        <h3>Crafting Your Unique Identity</h3>
        <div>
          <ul>
            <li>
              <h4>Discovery & Strategy</h4>
              <p>
                We start by getting to know your business, your industry, and
                your competitors. Through research and analysis, we identify key
                insights that shape your brand’s strategy.
              </p>
            </li>
            <li>
              <h4>Brand Identity Design</h4>
              <p>
                From logos and color palettes to typography and visual elements,
                we craft a cohesive and compelling brand identity that captures
                the essence of your business.
              </p>
            </li>
            <li>
              <h4>Brand Messaging</h4>
              <p>
                A strong brand needs a clear voice. We develop brand messaging
                that communicates your value proposition and connects with your
                target audience across all touchpoints.
              </p>
            </li>
            <li>
              <h4>Brand Guidelines</h4>
              <p>
                Consistency is key to building a strong brand. We provide
                comprehensive brand guidelines that ensure your brand is
                represented consistently across all platforms and media.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <h2>Why Choose Us?</h2>
        <ul>
          <li>
            <h4>Experience & Expertise:</h4>
            <p>
              Our team has a wealth of experience in branding, with a deep
              understanding of what it takes to build and maintain a successful
              brand.
            </p>
          </li>
          <li>
            <h4>Tailored Solutions:</h4>
            <p>
              We know that every business is unique. That’s why we offer
              customized branding solutions that are tailored to your specific
              needs and goals.
            </p>
          </li>
          <li>
            <h4>Collaborative Process:</h4>
            <p>
              We work closely with you throughout the entire branding process,
              ensuring that your vision is realized every step of the way.
            </p>
          </li>
          <li>
            <h4>Results-Driven:</h4>
            <p>
              Our focus is on creating a brand that not only looks great but
              also drives results. We measure our success by the impact your
              brand has on your business.
            </p>
          </li>
        </ul>
      </section>
      <section>
        <h2>Our Branding Services Include:</h2>
        <ul>
          <li>Logo Design</li>
          <li>Brand Identity Development</li>
          <li>Brand Strategy & Positioning</li>
          <li>Brand Messaging & Voice</li>
          <li>Visual & Verbal Identity</li>
          <li>Brand Guidelines & Manuals</li>
          <li>Rebranding & Brand Refresh</li>
          <li>Brand Consultation</li>
        </ul>
      </section>
      <section>
        <div>
          <h2>Let’s Build Your Brand</h2>
          <p>
            Ready to take your brand to the next level? Whether you’re starting
            from scratch or looking to refresh your existing brand, we’re here
            to help. Contact us today to learn more about our branding services
            and how we can help you create a powerful, lasting brand.
          </p>
        </div>
        <div>
          <h3>Get in Touch</h3>
          <p>
            <strong>Email:</strong>
          </p>
        </div>
      </section>
    </div>
  );
}

export default Branding