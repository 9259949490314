import React from 'react'

const OrderDetails = () => {
  return (
    <div>
      <div className="description">
        <h3>Order Details</h3>
      </div>
    </div>
  );
}

export default OrderDetails