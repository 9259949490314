import "./button.css"

const ButtonPrimary = ({
  ButtonPrimaryLink="#",
  ButtonPrimaryText="Button Title"
}) => {
  return (
    <div className="container__Button">
      <a href={ButtonPrimaryLink} className="button primaryButton">
        {ButtonPrimaryText}
      </a>
    </div>
  );
}

export default ButtonPrimary;